import React from 'react';
import styled from 'styled-components';

import fb from './../../../img/services/social-icons/facebook.svg';
import instagram from './../../../img/services/social-icons/instagram.svg';
import linkedin from './../../../img/services/social-icons/linkedin.svg';
import Pinterest from './../../../img/services/social-icons/Pinterest.svg';
import snapchat2 from './../../../img/services/social-icons/snapchat2.svg';
import tiktok from './../../../img/services/social-icons/tiktok.svg';
import twitter from './../../../img/services/social-icons/twitter.svg';
import youtube from './../../../img/services/social-icons/youtube.svg';


const Wrapper = styled.div`
  width: calc(100% - 80px);
  padding-right: 40px;
  padding-left: 40px;

  .inner {
    max-width: 1134px;
    width: 100%;
    margin: 0 auto;

    .wrap {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: stretch;
      align-content: stretch;
    }

    img {
      margin-right: 64px;
      margin-bottom: 64px;
      &:last-of-type {
        margin-right: 0px;
      }
    }
    @media (max-width: 1210px) {
      .wrap {
        max-width: 600px;
      }
      img:nth-of-type(4n) {
        margin-right: 0;
      }
    }
    @media (max-width: 620px) {
      .wrap {
        max-width: 450px;
      }
      img:nth-of-type(4n) {
        margin-right: 64px;
      }
      img:nth-of-type(3n) {
        margin-right: 0;
      }
    }
    @media (max-width: 620px) {
      .wrap {
        max-width: 300px;
      }
      img:nth-of-type(3n) {
        margin-right: 64px;
      }
      img:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
`;

function quote(props) {
  return (
    <Wrapper>
      <div className="inner">
        <div className="wrap">
          <img src={fb}></img>
          <img src={instagram}></img>
          <img src={linkedin}></img>
          <img src={Pinterest}></img>
          <img src={snapchat2}></img>
          <img src={tiktok}></img>
          <img src={twitter}></img>
          <img src={youtube}></img>
        </div>
      </div>
    </Wrapper>
  );
}

export default quote;
